<template>
  <layout-page :page-description="pageDescription">
    <template v-slot:toolbar-title>
      <v-toolbar-title>{{translate('API Keys')}}</v-toolbar-title>
    </template>
    <template v-slot:toolbar-items>
      <v-btn
        text
        :loading="details.loading"
        to="/apikeys/create-new"
        >
        <v-icon>create</v-icon> {{translate('Create')}}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          :label="translate('Search')"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :loading="details.loading"
          :headers="headers"
          :items="details.users"
          :search="search">
          <template v-slot:item.internal.edit="{item, value}">
            <v-btn
              text
              color="primary"
              :to="{name: 'edit-api-key', params: {apiKey: item.apiKey}}"
              >
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.internal.delete="{item, value}">
            <confirm-button :key="item.apiKey">
              <template v-slot:button="confirm">
                <v-btn text small icon color="warning" @click="confirm">
                  <v-icon>delete</v-icon>
                </v-btn>
              </template>
              <template v-slot:confirm="cancel">
                <v-item-group multiple>
                  <v-btn text small color="error" @click="deleteApiKey(item)">
                    <v-icon>delete</v-icon> {{translate('Delete this API key?', 'remove.apikey')}}
                  </v-btn>
                  <v-btn text small icon @click="cancel">
                    <v-icon>cancel</v-icon>
                  </v-btn>
                </v-item-group>
              </template>
            </confirm-button>
          </template>
          <template v-slot:item.roles="{item, value}">
            <v-chip v-for="role in getEnabledRoles(value)" :key="role">
              {{role}}
            </v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </layout-page>
</template>
<script>
import {mapGetters} from 'vuex'
import LayoutPage from '@/components/layout/layout-page.vue'
import ConfirmButton from '@/components/confirm-button'
export default {
  components: {
    LayoutPage,
    ConfirmButton
  },
  data: () => ({
    search: "",
    mutations: 1
  }),
  created () {
  },
  computed: {
    ...mapGetters(['api', 'translate', 'authenticatedUser', 'createPageDescription']),
    pageDescription () {
      return this.createPageDescription()
        .addBreadcrumb({ text: this.translate('API Keys'), to: {name: 'api-keys'}})
    },
    headers () {
      return [{
        text: '',
        sortable: false,
        value: 'internal.edit'
      }, {
        text: this.translate('API Key'),
        sortable: true,
        value: 'apiKey'
      }, {
        text: this.translate('Description', 'description'),
        sortable: true,
        value: 'description'
      }, {
        text: this.translate('Roles', 'roles'),
        sortable: false,
        value: 'roles'
      }, {
        text: this.translate('CIDR'),
        sortable: true,
        value: 'valid.cidr'
      }, {
        text: this.translate('From date', 'from'),
        sortable: true,
        value: 'valid.from'
      }, {
        text: this.translate('Due date'),
        sortable: true,
        value: 'valid.to'
      },
      {
        text: '',
        sortable: false,
        value: 'internal.delete'
      }]
    },
  },
  asyncComputed: {
    details: {
      async get () {
        let {users, roles} = this.mutations && await this.api.apiKeys.getApiKeyUsers()

        return {
          users,
          roles,
          loading: false
        }
      },
      default: {
        loading: true,
        roles: [],
        users: [],
      }
    }
  },
  methods: {
    getEnabledRoles (roles) {
      return Object.entries(roles).map(([role, enabled]) => enabled && role).filter(role => role)
    },
    async deleteApiKey (item) {
      await this.api.apiKeys.deleteApiKeyUser(item)
      let users = this.details.users
      let index = users.indexOf(item)
      users.splice(index, 1)
    }
  }
}
</script>
