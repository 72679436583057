var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-page',{attrs:{"page-description":_vm.pageDescription},scopedSlots:_vm._u([{key:"toolbar-title",fn:function(){return [_c('v-toolbar-title',[_vm._v(_vm._s(_vm.translate('API Keys')))])]},proxy:true},{key:"toolbar-items",fn:function(){return [_c('v-btn',{attrs:{"text":"","loading":_vm.details.loading,"to":"/apikeys/create-new"}},[_c('v-icon',[_vm._v("create")]),_vm._v(" "+_vm._s(_vm.translate('Create'))+" ")],1)]},proxy:true}])},[_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.translate('Search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"loading":_vm.details.loading,"headers":_vm.headers,"items":_vm.details.users,"search":_vm.search},scopedSlots:_vm._u([{key:"item.internal.edit",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-btn',{attrs:{"text":"","color":"primary","to":{name: 'edit-api-key', params: {apiKey: item.apiKey}}}},[_c('v-icon',[_vm._v("edit")])],1)]}},{key:"item.internal.delete",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('confirm-button',{key:item.apiKey,scopedSlots:_vm._u([{key:"button",fn:function(confirm){return [_c('v-btn',{attrs:{"text":"","small":"","icon":"","color":"warning"},on:{"click":confirm}},[_c('v-icon',[_vm._v("delete")])],1)]}},{key:"confirm",fn:function(cancel){return [_c('v-item-group',{attrs:{"multiple":""}},[_c('v-btn',{attrs:{"text":"","small":"","color":"error"},on:{"click":function($event){return _vm.deleteApiKey(item)}}},[_c('v-icon',[_vm._v("delete")]),_vm._v(" "+_vm._s(_vm.translate('Delete this API key?', 'remove.apikey'))+" ")],1),_c('v-btn',{attrs:{"text":"","small":"","icon":""},on:{"click":cancel}},[_c('v-icon',[_vm._v("cancel")])],1)],1)]}}],null,true)})]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
var value = ref.value;
return _vm._l((_vm.getEnabledRoles(value)),function(role){return _c('v-chip',{key:role},[_vm._v(" "+_vm._s(role)+" ")])})}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }